import { memo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';

import * as actionCreators from '../../../../store/actions';
import { bidssummaryParamType, ebidsParamType } from '../../../../types/biddetail';
import { contactInfoParamType, submitResponseParamType } from '../../../../types/ebids';
import { formatPhoneNumber, setTimeToDate, toastFn } from '../../../../utils/helpers';
import { Buttons } from '../../../customcontrols';
import { MemberType } from '../../../../utils/constants';
import { ModalPopUp } from '../../../common/modals/ModalPopUp';

import history from '../../../../utils/history';
import { useAuthContext } from 'src/auth/AuthProvider';
import { setSharedDetails } from '../../../../store/actions';

interface propsTypes {
  submitEbidResponseDetails: any;
  submitEbitsFiles: any;
  setEbidDetails: any;
  ebids: ebidsParamType;
  bidssummary: bidssummaryParamType;
  contactinfo?: contactInfoParamType;
}
const EbidResponseControl = (props: propsTypes) => {
  const { submitEbidResponseDetails, submitEbitsFiles, setEbidDetails, ebids, bidssummary } = props;
  const { auth } = useAuthContext();
  const dispatch = useDispatch();
  const { results } = bidssummary;
  const { bidID } = results;
  const { action_buttons, wizard = [], docs, contactinfo, responsedetails = '' } = ebids;

  const [current] = wizard.filter(item => item.status === 'current').map(item => item.name);

  const [modal, setModal] = useState(false);
  const [missedDocuments, setMissedDocuments] = useState([]);

  const parcialSubmit = (event: any) => {
    event.preventDefault();
    dispatch( setSharedDetails({ refreshEbidsResponsesData: true }));
    if (current === 'Contact Information') {
      setEbidDetails({ partialsubmit: true });
      document.getElementById('submitform')?.click();
    } else if (current === 'Documents Upload') {
      const { reqdocs, supplementaldocs } = docs;
      const alldocs = [...reqdocs, ...supplementaldocs];
      const incompleteDocs = alldocs.filter(item => item.option === 'EL' && !item.fileName);
      if (incompleteDocs.length > 0) {
        const missedDocNames = incompleteDocs.map(item => item.description) as [];
        setMissedDocuments(missedDocNames);
        setModal(true);
      } else {
        continueWithInCompleteResponse();
      }
    } else if (current === 'Review Bid') {
      cancelEbidResponse();
    }
  };

  const continueWithInCompleteResponse = () => {
    const { reqdocs, supplementaldocs } = docs;
    const alldocs = [...reqdocs, ...supplementaldocs];
    const check = alldocs.filter(item => item.option);
    const manual = alldocs.find(item => item.option && ['NS', 'MN', 'None'].includes(item.option));
    if ((check && check.length > 0) || manual) {
      setEbidDetails({ action_buttons: false });
      submitEbitsFiles({
        auth,
        reqdocs,
        supplementaldocs,
        bidID,
        bidId: bidID,
        partialsubmit: true,
      });
    } else {
      toastFn('error', 'Please Complete Upload & Try Again', 'document file Upload & Try Again');
    }
  };

  const submitResponse = (event: any) => {
    event.preventDefault();

    const submitResponseValue = {
      responseStatus: 'CP',
      partialsubmit: false,
      bidId: bidID,
      deletefile: false,
      internal: false,
      reviewebid: true,
    } as submitResponseParamType;

    if (auth?.memberType === MemberType.AgencyBuyer) {
      const { supplierId = '' } = responsedetails || {};
      if (contactinfo) {
        const dueDate = setTimeToDate(
          contactinfo.buyerresponsedate,
          `${contactinfo.buyerdueTime.value} ${contactinfo.buyerdueTimeZone.value}`,
        );
        submitResponseValue.responseStatus =
          contactinfo.buyerresponsestatus && contactinfo.buyerresponsestatus.value;
        submitResponseValue.responseDateTime = dueDate;
        submitResponseValue.responseMemberId = supplierId;
        submitResponseValue.altShortName = contactinfo.companyname;
        submitResponseValue.city = contactinfo.city;
        submitResponseValue.notes = contactinfo.notes;
        submitResponseValue.address1 = contactinfo.address1;
        submitResponseValue.address2 = contactinfo.address2;
        submitResponseValue.postalCode = contactinfo.postalCode;
        submitResponseValue.faxNumber =
          (contactinfo.faxNumber && formatPhoneNumber(contactinfo.faxNumber)) || null;
        submitResponseValue.phoneNumber =
          (contactinfo.phoneNumber && formatPhoneNumber(contactinfo.phoneNumber)) || null;
        submitResponseValue.faxExtension =
          (contactinfo.faxExtension && formatPhoneNumber(contactinfo.faxExtension)) || null;
        submitResponseValue.phoneExtension =
          (contactinfo.phoneExtension && formatPhoneNumber(contactinfo.phoneExtension)) || null;
        submitResponseValue.stateId = (contactinfo.state && contactinfo.state.value) || 0;
        submitResponseValue.countryId = (contactinfo.country && contactinfo.country.value) || 0;
        submitResponseValue.countyId = (contactinfo.county && contactinfo.county.value) || 0;
        submitResponseValue.totalPrice =
          (contactinfo.totalPrice &&
            parseFloat(
              contactinfo.totalPrice
                .toString()
                .replace(/ /g, '')
                .replace('$', '')
                .replace(/,/g, ''),
            )) ||
          null;
        submitResponseValue.altTotalPrice =
          (contactinfo.altTotalPrice &&
            parseFloat(
              contactinfo.altTotalPrice
                .toString()
                .replace(/ /g, '')
                .replace('$', '')
                .replace(/,/g, ''),
            )) ||
          null;
        submitResponseValue.AddressType = 'EA';
        submitResponseValue.reviewbidpage = true;
        submitResponseValue.reviewebid = true;
        submitResponseValue.responseStatus = 'CP';
        submitResponseValue.bidId = bidID;
        submitResponseValue.responseStatus = 'CP';
        submitResponseValue.partialsubmit = false;
        submitResponseValue.bidId = bidID;
        submitResponseValue.deletefile = false;
        submitResponseValue.internal = false;
        submitResponseValue.reviewebid = true;
      }
    }
    submitEbidResponseDetails({ ...submitResponseValue, auth });
  };

  const cancelEbidResponse = () => {
    history.push(
      `/${
        auth?.memberType === MemberType.AgencyBuyer ? 'buyers' : 'suppliers'
      }/bids/${bidID}/details`,
    );
  };

  return (
    <>
      {action_buttons ? (
        <>
          {current === 'Review Bid' ? (
            <li title='Submit Response'>
              <span className='staticLink' onClick={e => submitResponse(e)}>
                <i className='mdi mdi-upload-outline mr-1' />
                Submit Response
              </span>
            </li>
          ) : null}
          <li title='Save & Finish Later'>
            <span className='staticLink' onClick={e => parcialSubmit(e)}>
              <i className='mdi mdi-content-save-outline mr-1' /> Save & Finish Later
            </span>
          </li>

          <li title='Cancel'>
            <span className='staticLink' onClick={cancelEbidResponse}>
              <i className='mdi mdi-close mr-1' />
              Cancel
            </span>
          </li>
        </>
      ) : null}
      <ModalPopUp
        size='lg'
        title={'Confirmation'}
        closeModal={() => setModal(!modal)}
        isOpen={modal}
        backdrop='static'
      >
        <p>{`You have not yet uploaded a document for ${missedDocuments.join(
          ', ',
        )}. Until you upload a document for this, or indicate you will not be uploading a document, your response will be marked as incomplete.`}</p>
        <Buttons
          classNames='bttn-secondary mt-2'
          text='Go back and upload a document.'
          title='Go back and upload a document.'
          type='button'
          onClick={() => setModal(false)}
        />
        <Buttons
          classNames='bttn-primary  mt-2 float-right'
          text='Continue with an incomplete response.'
          title='Continue with an incomplete response.'
          type='button'
          onClick={() => {
            setModal(false);
            continueWithInCompleteResponse();
          }}
        />
      </ModalPopUp>
    </>
  );
};

export default connect(
  (state: any) => ({ ebids: state.ebids, bidssummary: state.bidssummary }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
)(memo(EbidResponseControl));
