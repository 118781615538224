/* eslint-disable max-lines */
import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import history from '../../utils/history';
import * as actionTypes from '../actionTypes';
import * as Api from '../services';
import * as bidApi from '../services/bids';
import { END, eventChannel } from 'redux-saga';
import { constants } from '../../utils/settings';
import * as sharedApi from '../services/shared';
import {
  deprecatedConvertotherTimezonetoUTC,
  deprecatedGetDateTimeZoneConverted,
  formatPhoneNumber,
  getTimeFromDate,
  getZoneFromDate,
  setTimeToDate,
  toastFn,
} from '../../utils/helpers';
import moment from 'moment-timezone';
import {
  BidExternalStatusType,
  BuyerEbidStatus,
  DeprecatedFullTimeZone,
  MemberType,
} from '../../utils/constants';
import { detailssubmitTypes, ebidresponseTypes, reponsesummaryTypes } from '../../types/ebids';
import { quotespayloadTypes } from '../../types/quotes';
import { WizardPage, WizardStatus } from '../../types/wizard';
import { wizard as initialWizard } from '../reducers/ebids';
import { Authorization } from 'src/types';

function createUploader(files: any) {
  let emit: { (arg0: number): void; (input: unknown): void };
  const chan = eventChannel((emitter: any) => {
    emit = emitter;
    return () => {};
  });
  const uploadProgressCb = ({ total, loaded }: any) => {
    const percentage = Math.round((loaded * 100) / total);
    emit(percentage);
    if (percentage === 100) emit(END);
  };
  const config = {
    onUploadProgress: uploadProgressCb,
    baseURL: constants.api.documentUrl,
  };

  const uploadPromise = Api.submitEbitFilesUpload(files, config);
  return [uploadPromise, chan];
}

function* uploadProgressWatcher(chan: any, description: any, upload_progress = [], doc: any): any {
  let upload_progress_new: any[] = upload_progress;
  upload_progress_new = [...upload_progress, { progress: 0 }];
  while (true) {
    const progress = yield take(chan);

    const stateItem: any = upload_progress_new.find(item => item.title === description);
    stateItem.description = description;
    stateItem.progress = progress;

    yield put({
      type: actionTypes.SET_EBIDS_DETAILS.TRIGGER,
      payload: { upload_progress: upload_progress_new },
    });
  }
}
// To get file format tabs
export function* watchEBitsFileFormats() {
  yield takeLatest(actionTypes.GET_EBIDS_FILE_FORMATS.TRIGGER, eBitsFileFormats);
}
function* eBitsFileFormats(action: quotespayloadTypes): any {
  yield put({ type: actionTypes.GET_EBIDS_FILE_FORMATS.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.getEBitsFileFormats, action.payload);
    const payload = response.data.result || [];

    yield put({ type: actionTypes.GET_EBIDS_FILE_FORMATS.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.GET_EBIDS_FILE_FORMATS.FAILURE,
      payload: { Error, payload: action.payload },
    });
  }
}

// To get response tabs
export function* watchEBitsResponseDetails() {
  yield takeEvery(actionTypes.GET_EBIDS_RESPONSE_DETAILS.TRIGGER, eBitsResponseDetails);
}

// TODO: add typing for payloads sent to saga generators, example:
// interface EBidsResponseDetails {
//   auth: Authorization;
//   responseId?: string;
//   memberId?: string;
//   bidId?: string;
//   showAmount?:boolean;
//   memberCall?:boolean;
// }

function* eBitsResponseDetails(action: { payload: any }): any {
  yield put({ type: actionTypes.GET_EBIDS_RESPONSE_DETAILS.REQUEST, meta: action.payload });

  try {
    const { responseId = '', memberId = '', bidId = '' } = action.payload;
    if (responseId && bidId) {
      action.payload.showAmount = true;
    } else if (memberId && bidId) {
      action.payload.memberCall = true;
      action.payload.showAmount = true;
    }
    let summaryData = yield select(state => state?.bidssummary?.results) || '';
    if (!summaryData?.bidId) {
      const summaryresponse = yield call(bidApi.getBidsSummary, { bidId: bidId });
      yield put({
        type: actionTypes.SET_BID_SUMMARY_DETAILS.TRIGGER,
        payload: { results: summaryresponse.data.result },
      });
      summaryData = summaryresponse.data.result;
    }
    const auth = action.payload.auth;
    const { memberType } = auth;
    if (
      (['Submit', 'ViewEdit'].includes(summaryData.eBidding) &&
        summaryData.bidExternalStatusType === BidExternalStatusType.Active) ||
      memberType === MemberType.AgencyBuyer
    ) {
      let value: any = '';
      let BidsResponseDetailsvalue: any = '';
      const { memberCall = true } = action.payload;
      if (memberCall) {
        const response = yield call(Api.getBidsResponseDetails, action.payload);
        value = yield response.data.result || '';
        BidsResponseDetailsvalue = yield response.data.result || '';
      }

      const shared = yield select(state => state.shared);
      const { othermemberinfo: memberinfo } = shared;
      const { mi } = memberinfo;
      const responsemember = {};
      let addressdata: any = '';

      if (value && (value.responderAddressId || value.supplierId)) {
        const responderaddress = yield call(bidApi.getbidresponderinfo, {
          bidResponderAddressId: value.responderAddressId || 0,
          supplierId: memberId ? memberId : value.supplierId,
        });
        addressdata =
          (responderaddress && responderaddress.data && responderaddress.data.result) || '';
      }

      /* if(!addressdata.stateId && !addressdata.countryId){
        if (!mi) {
          let apivalue: any = { isCurrentMember: true };
          if (value.supplierId && action.payload.responseId) {
            apivalue = { isCurrentMember: false, memberId: value.supplierId };
          } else if (action.payload.memberId) {
            apivalue = { isCurrentMember: false, memberId: action.payload.memberId };
          }
          const res = yield call(bidApi.getMemberInfo, apivalue);
          responsemember = res.data.result || '';
          yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { othermemberinfo: res.data.result } });
        }
      } */

      let memberInfo = mi && !action.payload.memberId ? memberinfo : responsemember;
      if (!value.address1) {
        memberInfo = {
          address1: memberInfo.a1,
          address2: memberInfo.a2,
          city: memberInfo.ct,
          county: memberInfo.cu,
          countyId: memberInfo.cui,
          country: memberInfo.c,
          countryAbbr: memberInfo.ca,
          countryId: memberInfo.ci,
          fax: memberInfo.f,
          faxExtension: memberInfo.fe,
          isCorporate: memberInfo.ic,
          isFreeDocDownload: memberInfo.ifd,
          memberId: memberInfo.mi,
          memberName: memberInfo.mn,
          memberStatus: memberInfo.ms,
          memberType: memberInfo.mt,
          phone: memberInfo.p,
          phoneExtension: memberInfo.pe,
          postalCode: memberInfo.pc,
          state: memberInfo.st,
          stateAbbr: memberInfo.sta,
          stateId: memberInfo.sti,
          federalTaxId: memberInfo.fti,
          companyname: memberInfo.mn,
        };
        value = { ...value, ...memberInfo };
      }

      if (addressdata && addressdata.stateId && addressdata.countryId) {
        const {
          phone: phone_nouse,
          phoneExtension: phoneExtension_nouse,
          ...supplieraddress
        } = addressdata;
        value = {
          ...value,
          ...supplieraddress,
          phone: BidsResponseDetailsvalue.phone ? BidsResponseDetailsvalue.phone : phone_nouse,
          phoneExtension: BidsResponseDetailsvalue.phoneExtension
            ? BidsResponseDetailsvalue.phoneExtension
            : phoneExtension_nouse,
        };
      }

      if (addressdata && !value.responderAddressId && addressdata.memberName) {
        value.contactName = addressdata.memberName;
      }

      let newstatelist = shared.stateslist || [];
      if (newstatelist.length === 0) {
        const stateresponse = yield call(sharedApi.getStatesList);
        const ntatelist = (stateresponse.data && stateresponse.data.result) || [];
        newstatelist = yield ntatelist.map(
          (items: { name: string; title: string; id: number }) => ({
            ...items,
            label: items.name || items.title,
            value: items.id,
          }),
        );
        yield put({
          type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
          payload: { stateslist: newstatelist },
        });
      }

      let countieslist = shared.countieslist || [];
      if (countieslist.length === 0 && value.stateId) {
        let countyresponse = yield call(Api.getGeoCounties, value.stateId);
        countyresponse = countyresponse.data.result;
        countieslist = yield countyresponse.map(
          (items: { name: string; title: string; id: number }) => ({
            ...items,
            label: items.name || items.title,
            value: items.id,
          }),
        );
        yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { countieslist } });
      }
      const filteredCounties =
        (countieslist &&
          countieslist.length > 0 &&
          countieslist.filter((county: { value: number }) => county.value === value.countyId)) ||
        [];
      const [selectedCounty] = filteredCounties;

      const auth = action.payload.auth;
      const { memberType = '' } = auth;

      const contactinfo: any = {
        companyname: value.contactName,
        address1: value.address1,
        address2: value.address2,
        city: value.city,
        postalCode: value.postalCode,
        phoneNumber: value.phone,
        phoneExtension: value.phoneExtension,
        faxNumber: value.faxNumber,
        faxExtension: value.faxExtension,
        bidAmtOptional: summaryData.bidAmtOptional,
        totalPrice:
          value.totalPrice &&
          value.totalPrice.toString().replace(/ /g, '').replace('$', '').replace(/,/g, ''),
        altTotalPrice:
          value.altTotalPrice &&
          value.altTotalPrice.toString().replace(/ /g, '').replace('$', '').replace(/,/g, ''),
        notes: value.notes,
        country: value.countryId
          ? shared &&
            shared.countrieslist.find((items: { value: number }) => items.value === value.countryId)
          : shared.countrieslist[0],
        county: selectedCounty,
        state:
          newstatelist
            .map((items: { name: string; id: number }) => ({
              ...items,
              label: items.name,
              value: items.id,
            }))
            .find((items: { id: number }) => items.id === value.stateId) || '',
      };

      const docs1 =
        (value.requiredDocuments &&
          value.requiredDocuments.map((item: { icon: string }) => ({
            ...item,
            ...{ option: item.icon || 'None' },
          }))) ||
        [];
      const docs2 =
        (value.supplementalDocuments &&
          value.supplementalDocuments.map((item: { icon: string }) => ({
            ...item,
            ...{ option: item.icon || 'None' },
          }))) ||
        [];

      if (memberType === MemberType.AgencyBuyer) {
        const bidssummary = yield select(state => state && state.bidssummary);
        const { results = {} } = bidssummary;
        const { tzfn = '' } = results;
        const responseDateTime = deprecatedGetDateTimeZoneConverted(value.responseDateTime, tzfn);
        contactinfo.buyerresponsestatus = value.responseStatus
          ? BuyerEbidStatus.find(item => item.label === value.responseStatus)
          : { label: 'Updated', value: 'UP' };
        contactinfo.buyerresponsedate =
          value.responseDateTime && value.responseDateTime !== '0001-01-01T00:00:00'
            ? moment(responseDateTime)
            : moment();
        contactinfo.buyerdueTime = value.responseDateTime
          ? getTimeFromDate(responseDateTime)
          : { value: '11:00', label: '11:00' };
        contactinfo.buyerdueTimeZone = value.responseDateTime
          ? getZoneFromDate(responseDateTime)
          : { value: 'PM', label: 'PM' };
      }
      yield put({
        type: actionTypes.GET_EBIDS_RESPONSE_DETAILS.SUCCESS,
        payload: { value, docs1, docs2, contactinfo },
      });
    } else {
      history.replace(`/suppliers/bids/${action.payload.bidId}/details`);
      yield put({ type: actionTypes.GET_EBIDS_RESPONSE_DETAILS.FAILURE });
      toastFn('error', 'Bid has been closed. Please contact the agency', action.payload.bidId);
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_EBIDS_RESPONSE_DETAILS.FAILURE,
      payload: { Error, payload: action.payload },
    });
  }
}

// To submit response tabs
export function* watchEBitsResponseDetailsSubmit() {
  yield takeLatest(actionTypes.SUBMIT_EBIDS_RESPONSE_DETAILS.TRIGGER, eBitsResponseDetailsSubmit);
}
function* eBitsResponseDetailsSubmit(action: detailssubmitTypes): any {
  yield put({ type: actionTypes.SUBMIT_EBIDS_RESPONSE_DETAILS.REQUEST, meta: action.payload });

  try {
    const { partialsubmit, deletefile, internal, ...datas } = action.payload;

    const summaryresponse = yield call(bidApi.getBidsSummary, { bidId: datas.bidId });
    yield put({
      type: actionTypes.SET_BID_SUMMARY_DETAILS.TRIGGER,
      payload: { results: summaryresponse.data.result },
    });
    const summaryData = summaryresponse.data.result;
    //const summaryData = yield select(state => state?.bidssummary?.results);
    const auth = datas.auth;
    const { memberType } = auth;
    if (
      (['Submit', 'ViewEdit'].includes(summaryData.eBidding) &&
        summaryData.bidExternalStatusType === BidExternalStatusType.Active) ||
      memberType === MemberType.AgencyBuyer
    ) {
      let values = {} as any;
      if (action.payload.formData) values = action.payload.formData;

      const ebids = yield select(state => state.ebids);
      const { responsedetails = '', partialsubmit: saveExistOpt = false } = ebids || {};
      const { supplierId = '' } = responsedetails || {};

      const saveAndExist = saveExistOpt ? saveExistOpt : partialsubmit;
      let finalvalue: any = action.payload;
      if (datas && values && values.companyname) {
        finalvalue = {
          bidId: datas.bidId || 0,
          altShortName: values.companyname,
          city: values.city,
          notes: values.notes,
          address1: values.address1,
          address2: values.address2,
          postalCode: values.postalCode,
          faxNumber: (values.faxNumber && formatPhoneNumber(values.faxNumber)) || null,
          phoneNumber: (values.phoneNumber && formatPhoneNumber(values.phoneNumber)) || null,
          faxExtension: (values.faxExtension && formatPhoneNumber(values.faxExtension)) || null,
          phoneExtension:
            (values.phoneExtension && formatPhoneNumber(values.phoneExtension)) || null,
          stateId: (values.state && values.state.value) || 0,
          countryId: (values.country && values.country.value) || 0,
          countyId: (values.county && values.county.value) || 0,
          totalPrice:
            !values.bidAmtOptional && values.totalPrice
              ? parseFloat(
                  values.totalPrice.toString().replace(/ /g, '').replace('$', '').replace(/,/g, ''),
                )
              : 0,
          altTotalPrice:
            (values.altTotalPrice &&
              parseFloat(
                values.altTotalPrice
                  .toString()
                  .replace(/ /g, '')
                  .replace('$', '')
                  .replace(/,/g, ''),
              )) ||
            null,
          AddressType: 'EA',
        };
      }

      if (memberType === MemberType.AgencyBuyer) {
        let responseDateTime =
          (values &&
            values.buyerresponsedate &&
            setTimeToDate(
              values.buyerresponsedate,
              `${values.buyerdueTime.value} ${values.buyerdueTimeZone.value}`,
            )) ||
          '';
        if (!responseDateTime) {
          responseDateTime = finalvalue.responseDateTime;
        }

        const bidssummary = yield select(state => state && state.bidssummary);
        const { results = {} } = bidssummary;
        const { tzfn = '' } = results;
        finalvalue.responseDateTime = deprecatedConvertotherTimezonetoUTC(responseDateTime, tzfn);
        if (!datas.reviewbidpage) {
          finalvalue.responseStatus =
            values && values.buyerresponsestatus && values.buyerresponsestatus.value;
          finalvalue.responseMemberId = supplierId;
        }
      }

      if (action.payload.memberId) {
        finalvalue.responseMemberId = action.payload.memberId;
        yield put({
          type: actionTypes.SET_EBIDS_DETAILS.TRIGGER,
          payload: { getEbitsResponseDetailsAPICall: false },
        });
      }

      const response = yield call(Api.submitEbitResponse, finalvalue);
      const success = yield (response.data.result && response.data.result.bidResponseId) || '';
      if (datas && values && values.companyname) {
        yield put({
          type: actionTypes.SET_EBIDS_DETAILS.TRIGGER,
          payload: { contactinfo: finalvalue },
        });
      }
      let wizard: WizardPage[] = initialWizard;
      if (action.payload && !saveAndExist && !action.payload.internal) {
        wizard = [
          { name: 'Contact Information', status: WizardStatus.Completed, id: 1 },
          { name: 'Documents Upload', status: WizardStatus.Current, id: 2 },
          { name: 'Review Bid', status: WizardStatus.Unavailable, id: 3 },
        ] as WizardPage[];
      }
      if (action.payload.reviewebid) {
        wizard = [
          { name: 'Contact Information', status: WizardStatus.Completed, id: 1 },
          { name: 'Documents Upload', status: WizardStatus.Completed, id: 2 },
          { name: 'Review Bid', status: WizardStatus.Current, id: 3 },
        ] as WizardPage[];
      }
      if (success && action.payload.deletefile) {
        const DocId = datas.bidResponseItems[0].bidDocId;
        const ebids = yield select(state => state.ebids);
        const { docs = {} } = ebids;
        const alldocs = [...docs.reqdocs, ...docs.supplementaldocs];
        let newreqdoc = [];
        let newsuppdoc = [];
        if (action.payload.bidResponseItems['0'].memberResponseItemId === 0) {
          const doc = alldocs.filter(item => item.bidDocId !== DocId);
          newreqdoc = doc.filter(item => item.memberResponseItemId > 0);
          newsuppdoc = doc.filter(item => item.memberResponseItemId === 0);
        } else {
          const stateItem = alldocs.find(item => item.bidDocId === DocId);
          stateItem.value = '';
          stateItem.bidDocId = 0;
          stateItem.fileName = '';
          stateItem.format = '';
          if (stateItem.option === 'EL') {
            stateItem.icon = '';
            stateItem.option = 'None';
            stateItem.statusName = '';
          }
          newreqdoc = alldocs.filter(item => item.memberResponseItemId > 0);
          newsuppdoc = alldocs.filter(item => item.memberResponseItemId === 0);
        }

        yield put({
          type: actionTypes.SET_EBIDS_DETAILS.TRIGGER,
          payload: {
            docs: { ...docs, ...{ reqdocs: newreqdoc, supplementaldocs: newsuppdoc } },
            pagehasValues: true,
          },
        });
      }

      yield put({ type: actionTypes.SET_EBIDS_DETAILS.TRIGGER, payload: { partialsubmit: false } });

      const userpath = memberType === MemberType.AgencyBuyer ? 'buyers' : 'suppliers';
      if (action.payload.bidId) {
        yield put({
          type: actionTypes.SET_EBIDS_DETAILS.TRIGGER,
          payload: { action_buttons: true },
        });
      }
      if (action?.payload?.reasonfor === 'agency-submit') {
        yield put({
          type: actionTypes.SET_EBIDS_DETAILS.TRIGGER,
          payload: { wizard, success, for: 'contact_info' },
        });
      }

      if (action.payload.memberId) {
        history.push(
          `/buyers/bids/${action.payload.bidId}/response/${response.data.result.bidResponseId}`,
        );
      }
      if (saveAndExist) {
        history.push(`/${userpath}/bids/${action.payload.bidId}/details`);
      }
      if (action.payload.reviewebid && action.payload.responseStatus && action.payload.bidId) {
        history.replace(`/${userpath}/bids/${action.payload.bidId}/details`);
      }
      yield put({ type: actionTypes.GET_AMPLITUDE_USER_PROPERTIES.TRIGGER });
      yield put({ type: actionTypes.SUBMIT_EBIDS_RESPONSE_DETAILS.SUCCESS });
    } else {
      history.replace(`/suppliers/bids/${action.payload.bidId}/details`);
      yield put({
        type: actionTypes.SUBMIT_EBIDS_RESPONSE_DETAILS.FAILURE,
        payload: { for: 'contact_info' },
      });
      toastFn('error', 'Bid has been closed. Please contact the agency', action.payload.bidId);
    }
  } catch (error) {
    if (action.payload.bidId) {
      yield put({ type: actionTypes.SET_EBIDS_DETAILS.TRIGGER, payload: { action_buttons: true } });
    }
    yield put({
      type: actionTypes.SUBMIT_EBIDS_RESPONSE_DETAILS.FAILURE,
      payload: { error, for: 'contact_info' },
    });
  }
}

// To submit file upload
export function* watchEBitsFilesSubmit() {
  yield takeLatest(actionTypes.SUBMIT_EBIDS_FILES_DOCS.TRIGGER, eBitsFilesSubmit);
}
function* eBitsFilesSubmit(action: {
  payload: { auth: Authorization; partialsubmit?: any; bidId?: any; bidID?: any };
}): any {
  yield put({ type: actionTypes.SUBMIT_EBIDS_FILES_DOCS.REQUEST, meta: action.payload });

  const { bidID } = action.payload;
  try {
    const summaryData = yield select(state => state?.bidssummary?.results);
    const auth = action.payload.auth;
    const { memberType } = auth;
    if (
      (['Submit', 'ViewEdit'].includes(summaryData.eBidding) &&
        summaryData.bidExternalStatusType === BidExternalStatusType.Active) ||
      memberType === MemberType.AgencyBuyer
    ) {
      const ebids = yield select(state => state.ebids);
      const { docs = {} } = ebids;
      const { reqdocs = [], supplementaldocs = [] } = docs;
      const alldocs = [...reqdocs, ...supplementaldocs];

      const { responsedetails = '' } = ebids || {};
      const { supplierId = '' } = responsedetails || {};

      const doc = alldocs
        .filter(item => item.value && item.value.length && !item.bidDocId)
        .map(item => {
          const newitem: any = {
            bidId: bidID || 0,
            memberResponseItemId: item.memberResponseItemId || 0,
            title: item.description || '',
            uploadFile: item.value,
          };
          if (memberType === MemberType.AgencyBuyer) {
            newitem.responseMemberId = supplierId;
          }
          return newitem;
        });

      let wizard = undefined;

      if (doc.length > 0) {
        yield put({
          type: actionTypes.SET_EBIDS_DETAILS.TRIGGER,
          payload: { upload_request: doc, upload_progress: doc },
        });
        const response = [];
        const ebids = yield select(state => state.ebids);
        const { files_response = [], upload_progress = [], docs } = ebids;
        for (const i in doc) {
          if ({}.hasOwnProperty.call(doc, i)) {
            const [uploadPromise, chan] = yield call(createUploader, doc[i]);
            yield fork(uploadProgressWatcher, chan, doc[i].title, upload_progress, doc);
            const uploadres = yield call(() => uploadPromise);
            response.push(uploadres);
            let upload_value = [
              { description: doc[i].title, bidDocId: uploadres.data.result.bidDocId },
            ];
            if (files_response.length > 0) {
              // const newvalue = files_response.filter(item => item.description !== doc[i].title)
              upload_value = [
                ...files_response,
                ...[{ description: doc[i].title, bidDocId: uploadres.data.result.bidDocId }],
              ];
            }
            yield put({
              type: actionTypes.SUBMIT_EBIDS_FILES_DOCS.SUCCESS,
              payload: { success: upload_value },
            });
          }
        }
        const resarr = response.map(item => ({
          bidDocId: item.data.result.bidDocId,
          memberResponseItemId: item.data.result.memberResponseItemId,
          title: item.data.result.title,
        }));
        const callarr = alldocs.map(item =>
          Object.assign(
            item,
            resarr.find(items => items.title === item.description),
          ),
        );

        const anothercallarr = callarr.map(items => ({
          memberResponseItemId: items.memberResponseItemId || 0,
          bidResponseItemStatus: (items.option === 'None' ? '' : items.option) || '',
          bidDocId: items.bidDocId || 0,
        }));
        const failed = response.filter(item => !item.data.result.bidDocId) || [];
        const success = response.filter(item => item.data.result.bidDocId) || [];
        yield put({
          type: actionTypes.SET_EBIDS_DETAILS.TRIGGER,
          payload: { upload_failed: failed, upload_success: success },
        });
        const succres = success.map(item => item.data.result.description);
        const newdoc = doc.filter(items => !succres.includes(items.description));
        const newreqdoc = callarr.filter(item => item.memberResponseItemId > 0);
        const newsuppdoc = callarr.filter(item => item.memberResponseItemId === 0);
        const newdocumnet = { reqdocs: newreqdoc, supplementaldocs: newsuppdoc };
        yield put({
          type: actionTypes.SET_EBIDS_DETAILS.TRIGGER,
          payload: { upload_request: newdoc, docs: { ...docs, ...newdocumnet } },
        });
        if (failed.length === 0) {
          yield put({
            type: actionTypes.SUBMIT_EBIDS_FILES_DOCS.SUCCESS,
            payload: { wizard, success },
          });
          yield put({
            type: actionTypes.SUBMIT_EBIDS_RESPONSE_DETAILS.TRIGGER,
            payload: {
              bidId: bidID,
              partialsubmit: false,
              bidResponseItems: anothercallarr,
              internal: true,
            },
          });
          yield put({
            type: actionTypes.SET_EBIDS_DETAILS.TRIGGER,
            payload: { pagehasValues: false },
          });

          yield delay(400);
          yield put({
            type: actionTypes.SET_EBIDS_DETAILS.TRIGGER,
            payload: { upload_progress: [], upload_request: [] },
          });
          if (action.payload.partialsubmit) {
            history.push(
              `/${memberType === MemberType.AgencyBuyer ? 'buyers' : 'suppliers'}/bids/${
                action.payload.bidId
              }/details`,
            );
          }
        } else {
          yield put({
            type: actionTypes.SET_EBIDS_DETAILS.TRIGGER,
            payload: { action_buttons: true },
          });
          yield put({
            type: actionTypes.SUBMIT_EBIDS_FILES_DOCS.FAILURE,
            payload: { error: 'Upload Failed' },
          });
        }
      } else {
        const anothercallarr = alldocs.map(items => ({
          memberResponseItemId: items.memberResponseItemId || 0,
          bidResponseItemStatus: (items.option === 'None' ? '' : items.option) || '',
          bidDocId: items.bidDocId || 0,
        }));
        const ebids = yield select(state => state.ebids);
        const { responsedetails = '' } = ebids || {};
        const { supplierId = '' } = responsedetails || {};
        const newdata: any = {
          bidId: bidID,
          partialsubmit: action.payload.partialsubmit ? action.payload.partialsubmit : false,
          bidResponseItems: anothercallarr,
          internal: true,
          reviewbidpage: true,
          auth:auth,
        };
        if (memberType === MemberType.AgencyBuyer) {
          newdata.memberId = supplierId;
        }
        yield put({ type: actionTypes.SUBMIT_EBIDS_RESPONSE_DETAILS.TRIGGER, payload: newdata });
        yield delay(500);
        if (action.payload.partialsubmit) {
          history.push(
            `/${memberType === MemberType.AgencyBuyer ? 'buyers' : 'suppliers'}/bids/${
              action.payload.bidId
            }/details`,
          );
        } else {
          wizard = [
            { name: 'Contact Information', status: WizardStatus.Completed, id: 1 },
            { name: 'Documents Upload', status: WizardStatus.Completed, id: 2 },
            { name: 'Review Bid', status: WizardStatus.Current, id: 3 },
          ] as WizardPage[];
          yield delay(500);
          yield put({
            type: actionTypes.SET_EBIDS_DETAILS.TRIGGER,
            payload: { action_buttons: true, getEbitsResponseDetailsAPICall:true },
          });
          yield put({ type: actionTypes.SUBMIT_EBIDS_FILES_DOCS.SUCCESS, payload: { wizard } });
        }
      }
    } else {
      yield put({ type: actionTypes.SUBMIT_EBIDS_FILES_DOCS.FAILURE });
      history.replace(`/suppliers/bids/${bidID}/details`);
      toastFn('error', 'Bid has been closed. Please contact the agency', bidID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_EBIDS_FILES_DOCS.FAILURE,
      payload: { error, payload: action.payload },
    });
    yield put({ type: actionTypes.SET_EBIDS_DETAILS.TRIGGER, payload: { action_buttons: true } });
  }
}

function* watchEbidResponse() {
  yield takeLatest(actionTypes.GET_EBID_RESPONSE.TRIGGER, setEbidResponse);
}

function* setEbidResponse(action: ebidresponseTypes): any {
  yield put({ type: actionTypes.GET_EBID_RESPONSE.REQUEST, meta: action.payload });
  yield put({
    type: actionTypes.UPDATE_INTERNAL_LOADER.ACTION,
    payload: { page: 'ebidresponse', type: 'add' },
  });
  try {
    const { internaldetails = {}, isInternalUse = false, ...otherdata } = action.payload;
    const response = yield call(Api.getEbidResponse, otherdata);
    // const Ebidreesponsedata = response.data.result || ''
    if (isInternalUse) {
      if (response.data.result && response.data.result.length > 0) {
        const item = response.data.result[0];
        yield put({
          type: actionTypes.LOAD_BID_EBIDS.TRIGGER,
          payload: { memberId: internaldetails.memberId, bidId: item.bidId },
        });
      }
    }
    /*		if (Ebidreesponsedata && Ebidreesponsedata.responderAddressId) {
			yield put({
				type: actionTypes.SET_EBID_SUMMARY.TRIGGER,
				payload: {
					bidResponderAddressID: Ebidreesponsedata.responderAddressId,
					supplierId: Ebidreesponsedata.supplierId
				}
			})
		}*/
    yield put({ type: actionTypes.GET_EBID_RESPONSE.SUCCESS, payload: response.data.result });
    yield put({
      type: actionTypes.UPDATE_INTERNAL_LOADER.ACTION,
      payload: { page: 'ebidresponse', type: 'remove' },
    });
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { internalLoader: false } });
  } catch (error) {
    yield put({
      type: actionTypes.GET_EBID_RESPONSE.FAILURE,
      payload: { payload: action.payload, error },
    });
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { internalLoader: false } });
  }
}

function* watchEbidResponseSummary() {
  yield takeLatest(actionTypes.SET_EBID_SUMMARY.TRIGGER, getEbidResponseSummary);
}

function* getEbidResponseSummary(action: reponsesummaryTypes): any {
  yield put({ type: actionTypes.SET_EBID_SUMMARY.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getEbidResponseSummary, {
      responseId: action.payload.responseId,
      bidId: action.payload.bidId,
      showAmount: true,
    });
    let ebidsummarydata = response.data.result || '';
    ebidsummarydata.index = action.payload.index;
    ebidsummarydata.supplierName = action.payload.supplierName;
    ebidsummarydata.memberId = action.payload.memberId;

    let addressinfo: any = '';
    if (ebidsummarydata.responderAddressId) {
      const contactresponse = yield call(bidApi.getbidresponderinfo, {
        bidResponderAddressID: ebidsummarydata.responderAddressId || 0,
        supplierId: ebidsummarydata.supplierId,
      });
      addressinfo = contactresponse.data.result || '';
    }
    if (addressinfo) {
      ebidsummarydata = {
        ...ebidsummarydata,
        ...addressinfo,
        phone: ebidsummarydata.phone,
        phoneExtension: ebidsummarydata.phoneExtension,
      };
    } else {
      const contactInfo = yield call(bidApi.getbidresponderinfo, {
        bidResponderAddressID: action.payload.responseId || 0,
        // memberId: action.payload.memberId,
        supplierId: action.payload.memberId,
      });
      const contactInfodata = contactInfo.data.result || '';
      if (contactInfo) {
        if (!ebidsummarydata.address1) ebidsummarydata.address1 = contactInfodata.address1;
        if (!ebidsummarydata.address2) ebidsummarydata.address2 = contactInfodata.address2;
        if (!ebidsummarydata.city) ebidsummarydata.city = contactInfodata.city;
        if (!ebidsummarydata.state) ebidsummarydata.state = contactInfodata.state;
        if (!ebidsummarydata.country) ebidsummarydata.country = contactInfodata.country;
        if (!ebidsummarydata.county) ebidsummarydata.county = contactInfodata.county;
        if (!ebidsummarydata.postalCode) ebidsummarydata.postalCode = contactInfodata.postalCode;
        if (!ebidsummarydata.declaredAttributes)
          ebidsummarydata.declaredAttributes = contactInfodata.declaredAttributes;
      }
    }

    yield put({ type: actionTypes.SET_EBID_SUMMARY.SUCCESS, payload: ebidsummarydata });
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { internalLoader: false } });
  } catch (error) {
    yield put({
      type: actionTypes.SET_EBID_SUMMARY.FAILURE,
      payload: { payload: action.payload, error },
    });
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { internalLoader: false } });
  }
}

export function* eBitsSaga() {
  yield fork(watchEBitsFileFormats);
  yield fork(watchEBitsResponseDetails);
  yield fork(watchEBitsResponseDetailsSubmit);
  yield fork(watchEBitsFilesSubmit);
  yield fork(watchEbidResponse);
  yield fork(watchEbidResponseSummary);
}
