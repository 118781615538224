import React, { memo, useEffect, useState } from 'react';
import { FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap';
// import close from '../../assets/images/clsoe-new.png'
import { limitedloginPreviousUrlName } from '../../utils/constants';
import { limitedbiddetailsTypes } from '../../types/limited';

import imgsCdn from '../../assets/images/imagescdn';
import { Link } from 'react-router-dom';
import { useAuthContext } from 'src/auth/AuthProvider';

const close = imgsCdn.otherImg.closeIcon;

interface propsTypes {
  match?: any;
  login?: any;
  setLoginDetails?: any;
  limited: limitedbiddetailsTypes;
  setLimitedDetails?: any;
  size: string;
}

function SigninPopup(props: propsTypes) {
  const [logindetails, setLogindetails] = useState({ USERNAME: '', PASSWORD: '' });
  const [initialError, setError] = useState(false);
  const { setLoginDetails } = props;
  const { handleLimitedPageLogin, hasError, setHasError } = useAuthContext();
  const { USERNAME, PASSWORD } = logindetails;
  const { limited, setLimitedDetails } = props;
  const { signinmodal, orderBidPackage = false } = limited;
  const bidId = parseInt(props.match.params.bidId) || '';
  const [isLoggedinPostClick, setIsLoggedinPostClick] = useState(false);

  const closeModal = () => {
    setLimitedDetails({ signinmodal: false });
  };

  const handleInputChange = (event: { target: any }) => {
    const { type, checked, value, name } = event && event.target;
    const values = type === 'checkbox' ? checked : value;
    setLogindetails({ ...logindetails, [name]: values.trim() });
    setIsLoggedinPostClick(false);
    if (hasError) setHasError(false);
    if (initialError) setError(false);
  };

  const loginClick = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (USERNAME && PASSWORD) {
      handleLimitedPageLogin({
        userName: USERNAME,
        password: PASSWORD,
        pagefor: 'popup',
        orderBidPackage,
        pageBid: bidId,
      });
      sessionStorage.setItem(limitedloginPreviousUrlName, `/bids/${bidId}/details`);
    } else {
      if (!initialError) setError(true);
      if (hasError) setHasError(false);
    }
    if (!isLoggedinPostClick && signinmodal && USERNAME && PASSWORD) {
      setIsLoggedinPostClick(true);
    }
  };

  useEffect(() => {
    return () => {
      setLoginDetails({ her: false });
    };
  }, [setLoginDetails]);

  return (
    <>
      <Modal isOpen={signinmodal} toggle={closeModal} size='md' className='signinPopup'>
        <ModalBody>
          <button
            title='Close'
            onClick={closeModal}
            type='button'
            className='close'
            aria-label='Close'
          >
            {/* <span aria-hidden='true'>×</span> */}
            <img src={close} width='30px' alt='Close' />
          </button>
          <h3>Login</h3>
          <form onSubmit={loginClick}>
            <FormGroup>
              <Label for='email'>Username</Label>
              <div className='mr-4'>
                <input
                type='text'
                className='form-control'
                name='USERNAME'
                value={USERNAME}
                onChange={handleInputChange}
                maxLength={80}
              />
             
              {initialError && !USERNAME ? (
                <p className='emailError'>Enter Valid Username</p>
              ) : null}
               </div>
            </FormGroup>
            <FormGroup>
              <Label for='password'>Password</Label>
              <div className='mr-4'>
              <input
                type='password'
                className='form-control'
                name='PASSWORD'
                // placeholder='Enter Password'
                value={PASSWORD}
                onChange={handleInputChange}
                maxLength={40}
              />

              {initialError && !PASSWORD ? (
                <p className='errorPassword'>Enter Valid Password</p>
              ) : null}
              </div>
            </FormGroup>
            <div className='mr-5 ml-3'>
            {hasError ? (
              <p className='apiError'> Invalid username or password. Please try again. </p>
            ) : null}
            </div>
            <FormGroup check>
              <Label check>
                <Input type='checkbox' /> Remember me
              </Label>
            </FormGroup>
            <Label className='help'>
              <Link to='/accountrecovery' title='Need help logging in ?'>
                Need help logging in ?
              </Link>
            </Label>
            <div className='d-block btnWrapper'>
              <button className='btn-primary' name='login' type='submit'>
                Login
              </button>
              or{' '}
              <Link to='/registration' title='Need help logging in ?'>
                Sign Up
              </Link>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}

export default memo(SigninPopup);
