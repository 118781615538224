import { axiosBarePostAuthenticated, axiosBareRequestAuthenticated } from 'src/store/services/api';
import {
  ContractDetailsAPI,
  ContractDocumentAPIResponse,
  ContractDocumentType,
} from '../../contract-management.d';

import { constants } from 'src/utils/settings';
import { paths } from '../../ContractManagement.paths';

export async function updateContractDocument(contractDocument: ContractDocumentAPIResponse) {
  const updateContractDocResponse = await axiosBarePostAuthenticated({
    baseURL: constants.api.reminderUrl,
    data: {id:contractDocument.contractId ?? '',
      lstDocumentCreateRequests:[{
          ...contractDocument,
          awardeeContactId: contractDocument.awardeeContactId ?? ""}]},
    url: paths.api.documents.update,
  }) as {data:ContractDetailsAPI};
  return updateContractDocResponse.data ;
}

export async function deleteContractDocument(
  contractDocument: Pick<ContractDocumentAPIResponse, 'contractId' | 'documentId'>,
) {
  const delcontractDocResponse = await axiosBareRequestAuthenticated({
    baseURL: constants.api.reminderUrl,
    method: 'DELETE',
    url: paths.api.documents.delete(contractDocument.contractId || '', contractDocument.documentId),
  }) as{data:ContractDetailsAPI};
  return delcontractDocResponse.data ;
}
export async function getContractDocumentTypes() {
  const getDocTypesResponse = await axiosBareRequestAuthenticated({
    baseURL: constants.api.reminderUrl,
    url: paths.api.documents.types.list,
  })  as {data:ContractDocumentType[]};
  return  getDocTypesResponse.data;
}

export  async function updateContractDocumentType(documentType: ContractDocumentType) {
  const UpdateContDocTypeResponse = await axiosBarePostAuthenticated({
    baseURL: constants.api.reminderUrl,
    data: {
      ...documentType,id:documentType.id ?? '',
    },
    url: documentType.id
      ? paths.api.documents.types.update
      : paths.api.documents.types.create,
  }) as {data:ContractDocumentType};
  return UpdateContDocTypeResponse.data;
}
