import { memo, useEffect } from 'react';
import { Table } from 'reactstrap';

import {
  contactInfoParamType,
  initialEbidsTypes,
  submitResponseParamType,
} from '../../../types/ebids';
import { docItemParamType, docParamType } from '../../../types/biddetail';
import {
  formatPhoneNumber,
  formatPrice,
  formattedPhoneNumber,
  setTimeToDate,
} from '../../../utils/helpers';
import { WizardPage, WizardStatus } from '../../../types/wizard';
import { Buttons } from '../../customcontrols';
import { MemberType } from '../../../utils/constants';
import { TollFreeNumber } from 'src/utils/texts/common';
import { useAuthContext } from 'src/auth/AuthProvider';
import { setSharedDetails } from '../../../store/actions';
import { useDispatch } from 'react-redux';

const tdWidth = { width: '200px' };

interface propsTypes {
  setEbitPage?: any;
  docs?: docParamType;
  submitEbidResponseDetails?: any;
  bidsResponseDocumentDownload?: any;
  getEbitsResponseDetails?: any;
  contactinfo?: contactInfoParamType;
  responsedetails?: { supplierId: number };
  match?: any;
  ebids?: initialEbidsTypes;
}

const ReviewBid = (props: propsTypes) => {
  // Getting redux values & fns from parent
  const {
    setEbitPage,
    docs,
    submitEbidResponseDetails,
    bidsResponseDocumentDownload,
    getEbitsResponseDetails,
    contactinfo,
    responsedetails = '',
    ebids,
    match,
  } = props;
  const { auth } = useAuthContext();
  const { getEbitsResponseDetailsAPICall } = ebids || {};
  const { reqdocs, supplementaldocs = [] } = docs || {};
  const bidID = match?.params?.bidId || '';
  const responseId = match?.params?.responseId || '';
  const memberId = match?.params?.memberId || '';
  const dispatch = useDispatch();


  // to get response tabs
  useEffect(() => {
    if (bidID && getEbitsResponseDetailsAPICall) {
      getEbitsResponseDetails({ auth, bidId: bidID, responseId, memberId });
    }
  }, [auth, getEbitsResponseDetails, bidID, responseId, memberId, getEbitsResponseDetailsAPICall]);

  // Going to previous page using action fn from parent component
  const setPage = () => {
    const wizard: WizardPage[] = [
      { name: 'Contact Information', status: WizardStatus.Completed, id: 1 },
      { name: 'Documents Upload', status: WizardStatus.Current, id: 2 },
      { name: 'Review Bid', status: WizardStatus.Unavailable, id: 3 },
    ];
    setEbitPage({ wizard });
  };

  // required/Supplementary documents list component
  const showdocuments = (docarr: docItemParamType[]) => {
    return docarr.map((items, index) => (
      <li key={index}>
        <span
          className={`
            ${(items.icon || items.option) === 'EL' && items.bidDocId ? 'successTick' : ''}
						${(items.icon || items.option) === 'MN' ? 'manual' : ''}
						${
              (items.icon || items.option) === 'NS' ||
              ((items.icon || items.option) === 'EL' && !items.bidDocId)
                ? 'notSubmit'
                : ''
            }
						${(items.icon || items.option) === 'None' ? 'noneDot' : ''}
				`}
        />
        {items.bidDocId ? (
          <span
            className='staticLink'
            onClick={e => {
              e.preventDefault();
              bidsResponseDocumentDownload({ docId: items.bidDocId, id: bidID, type: 'Bid' });
            }}
          >
            {items.description}
          </span>
        ) : (
          <>{items.description}</>
        )}
        {items.statusName ? <span> ({items.statusName})</span> : <span> (None)</span>}
      </li>
    ));
  };

  const submitResponseValue = {
    responseStatus: 'CP',
    partialsubmit: false,
    bidId: bidID,
    deletefile: false,
    internal: false,
    reviewebid: true,
  } as submitResponseParamType;

  if (auth?.memberType === MemberType.AgencyBuyer) {
    const { supplierId = '' } = responsedetails || {};
    if (contactinfo) {
      const dueDate = setTimeToDate(
        contactinfo.buyerresponsedate,
        `${contactinfo.buyerdueTime.value} ${contactinfo.buyerdueTimeZone.value}`,
      );
      submitResponseValue.responseStatus = 'CP';
      submitResponseValue.responseDateTime = dueDate;
      submitResponseValue.responseMemberId = supplierId;
      submitResponseValue.altShortName = contactinfo.companyname;
      submitResponseValue.city = contactinfo.city;
      submitResponseValue.notes = contactinfo.notes;
      submitResponseValue.address1 = contactinfo.address1;
      submitResponseValue.address2 = contactinfo.address2;
      submitResponseValue.postalCode = contactinfo.postalCode;
      submitResponseValue.faxNumber =
        (contactinfo.faxNumber && formatPhoneNumber(contactinfo.faxNumber)) || null;
      submitResponseValue.phoneNumber =
        (contactinfo.phoneNumber && formatPhoneNumber(contactinfo.phoneNumber)) || null;
      submitResponseValue.faxExtension =
        (contactinfo.faxExtension && formatPhoneNumber(contactinfo.faxExtension)) || null;
      submitResponseValue.phoneExtension =
        (contactinfo.phoneExtension && formatPhoneNumber(contactinfo.phoneExtension)) || null;
      submitResponseValue.stateId = (contactinfo.state && contactinfo.state.value) || 0;
      submitResponseValue.countryId = (contactinfo.country && contactinfo.country.value) || 0;
      submitResponseValue.countyId = (contactinfo.county && contactinfo.county.value) || 0;
      submitResponseValue.totalPrice =
        (contactinfo.totalPrice &&
          parseFloat(
            contactinfo.totalPrice.toString().replace(/ /g, '').replace('$', '').replace(/,/g, ''),
          )) ||
        null;
      submitResponseValue.altTotalPrice =
        (contactinfo.altTotalPrice &&
          parseFloat(
            contactinfo.altTotalPrice
              .toString()
              .replace(/ /g, '')
              .replace('$', '')
              .replace(/,/g, ''),
          )) ||
        null;
      submitResponseValue.AddressType = 'EA';
      submitResponseValue.reviewbidpage = true;
    }
  }

  return (
    <>
      <h4>Contact Info</h4>
      <div className='innerColIndent'>
        <Table borderless className='tableData'>
          <tbody>
            <tr>
              <th style={tdWidth}>Company Name</th>
              <td>{contactinfo && contactinfo.companyname}</td>
            </tr>
            <tr>
              <th>Address 1</th>
              <td>{contactinfo && contactinfo.address1}</td>
            </tr>
            <tr>
              <th>Address 2</th>
              <td>{contactinfo && contactinfo.address2}</td>
            </tr>
            <tr>
              <th>City</th>
              <td>{contactinfo && contactinfo.city}</td>
            </tr>
            <tr>
              <th>State</th>
              <td>{contactinfo && contactinfo.state && contactinfo.state.label}</td>
            </tr>
            <tr>
              <th>Country</th>
              <td>{contactinfo && contactinfo.country && contactinfo.country.label}</td>
            </tr>
            <tr>
              <th>Postal Code</th>
              <td>{contactinfo && contactinfo.postalCode}</td>
            </tr>
            <tr>
              <th>Phone Number</th>
              <td>
                {contactinfo && contactinfo.phoneNumber
                  ? formattedPhoneNumber(contactinfo.phoneNumber)
                  : ''}
              </td>
            </tr>
            {/*	<tr>
						<th>Fax</th>
						<td>{contactinfo.faxNumber}</td>
					</tr>*/}
            <tr>
              <th>Bid Amount</th>
              <td>
                {contactinfo &&
                  contactinfo.totalPrice &&
                  `$${formatPrice(contactinfo.totalPrice, 2)}`}
              </td>
            </tr>
            <tr>
              <th>Alternate Bid Amount</th>
              <td>
                {contactinfo &&
                  contactinfo.altTotalPrice &&
                  `$${formatPrice(contactinfo.altTotalPrice, 2)}`}
              </td>
            </tr>
            <tr>
              <th>Notes</th>
              <td>{contactinfo && contactinfo.notes}</td>
            </tr>
          </tbody>
        </Table>
      </div>
      {reqdocs && reqdocs.length > 0 ? (
        <>
          <h4>Agency Required Documents</h4>
          <div className='innerColIndent'>
            <ul className='list-unstyled'>{showdocuments(reqdocs)}</ul>
          </div>
        </>
      ) : null}
      {supplementaldocs.length > 0 ? (
        <>
          <h4>Supplemental Documents</h4>
          <div className='innerColIndent'>
            <ul className='list-unstyled'>{showdocuments(supplementaldocs)}</ul>
          </div>
        </>
      ) : null}

      <div className='noteImportant px-5'>
        <i className='mdi mdi-information-outline' /> After clicking &quot;Submit Response&quot; the
        following process will begin:
        <ol>
          <li>We will verify that your response is complete as entered.</li>
          <li>
            You will see a confirmation page with your confirmation number and date/time stamp of
            your upload.
          </li>
          <li>
            You will receive a confirmation e-mail indicating a successful response submittal.
          </li>
          <li>You may track your response submission under the Responses page.</li>
        </ol>
        If you do not receive any of the above, please call Supplier Services at {TollFreeNumber}.
      </div>
      <Buttons classNames='bttn-primary mt-4 mb-3' text='Previous' onClick={setPage} />
      <Buttons
        classNames='bttn-primary mt-4 mb-3 float-right'
        text='Submit Response'
        onClick={() => {submitEbidResponseDetails({ ...submitResponseValue, auth });
        dispatch( setSharedDetails({ refreshEbidsResponsesData: true }));}}
      />
    </>
  );
};

export default memo(ReviewBid);
