import { DSPill, DSPillType } from '@demandstar/components/pill';

import { capitalize } from 'src/utils/helpers';
import { ContractStatus } from '../contract-management.d';
import { useContractDetails } from '../useContractDetails';

type ContractStatusPillProps = {
  contractId: string;
};

const contractStatusPillMap = {
  [ContractStatus.Active]: DSPillType.Primary,
  [ContractStatus.Cancelled]: DSPillType.Error,
  [ContractStatus.Closed]: DSPillType.Success,
  [ContractStatus.Draft]: DSPillType.Warning,
};

export const ContractStatusPill = ({ contractId }: ContractStatusPillProps) => {
  const { contractDetails } = useContractDetails(contractId);

  if (!contractDetails) {
    return <></>;
  }

  const status = contractDetails.status;

  const pillLabel = status ? capitalize(status):'' ;

  const pillType = contractStatusPillMap[status];

  return <DSPill type={pillType}>{pillLabel}</DSPill>;
};
