import {
  Colors,
  ContentRowWrapper,
  DashboardRightColumn,
  HighlightedDetailsWrapper,
  HighlightedListWrapper,
  LeftColumnAbove,
} from '../../../../shared/styles';
import { BID_RESPONSES_LOADING_MESSAGE, navigationPaths, RESPONSE_LABEL_PLURAL } from '../../../../shared/constants';
import { ArrowTitle } from '../../../../components/layout/titles';
import { BidResponseDetailsPanel } from './BidResponseDetailsPanel';
import { BidResponseListPanel } from './BidResponseListPanel';
import { EditBidResponseButton } from './EditBidResponseButton';
import { MY_RESPONSE_LABEL_PLURAL } from '../../../../shared/constants/required-action';
import { useSetBreadcrumb } from '../../../../shared/hooks';
import { useRecoilRefresher_UNSTABLE } from 'recoil';
import { bidResponseDetailsState } from '../../../../store/recoil/bidResponseState';
import { useEBidResponses } from '../../../../store/recoil/ebid-responses/useEBidResponses';
import { useDispatch } from 'react-redux';
import { setSharedDetails } from '../../../../store/actions';
import { useEffect } from 'react';
import { useDSSelector } from '../../../../store/reducers';
import { DSLoadingPanel } from '../../../../library';

/**
 * Presentational component - view layout for responses
 * @returns JSX
 */
export function BidResponses() {
  const refreshResponseDetails = useRecoilRefresher_UNSTABLE(bidResponseDetailsState);
  const {reloadResponsesList,refreshEBidResponses}  = useEBidResponses();
  const dispatch = useDispatch();
  const refreshEbidsResponsesData = useDSSelector(state => state.shared.refreshEbidsResponsesData);
  useSetBreadcrumb({
    altname: MY_RESPONSE_LABEL_PLURAL,
    component: <EditBidResponseButton />,
    menuactive: navigationPaths.subPaths.Responses,
    name: MY_RESPONSE_LABEL_PLURAL,
    page: RESPONSE_LABEL_PLURAL,
    title: MY_RESPONSE_LABEL_PLURAL,
  });

  useEffect(() => {
    if (refreshEbidsResponsesData) {
      reloadResponsesList();
      refreshEBidResponses();
      refreshResponseDetails();
      dispatch( setSharedDetails({ refreshEbidsResponsesData: false }));
    }
  }, [refreshEbidsResponsesData,dispatch,refreshEBidResponses,refreshResponseDetails,reloadResponsesList]);

  return (
    <>
      <ArrowTitle bgColor={Colors.blueGreenArrowHighlight} label={RESPONSE_LABEL_PLURAL} />
      {refreshEbidsResponsesData ? <DSLoadingPanel>{BID_RESPONSES_LOADING_MESSAGE}</DSLoadingPanel> :
      <ContentRowWrapper>
        <LeftColumnAbove>
          <HighlightedListWrapper>
            <BidResponseListPanel />
          </HighlightedListWrapper>
        </LeftColumnAbove>
        <DashboardRightColumn>
          <BidResponseDetailsPanel />
        </DashboardRightColumn>
      </ContentRowWrapper>}
    </>
  );
}
