import { Cell, Column, SortingRule } from 'react-table';
import React, { useCallback, useMemo, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { DSPill, DSPillType } from '@demandstar/components/pill';

import { DSLink } from '@demandstar/components/link';
import { DSPaginate } from '@demandstar/components/paginate';
import { DSTable } from '@demandstar/components/table';
import { Id } from '@demandstar/components/types';

import { Assert, displayDate, scrollToTop } from 'src/utils/helpers';
import { BoldText, SpanSpacerLeft, SpanSpacerRight } from 'src/shared/styles';
import { commonLabels, ControlLabels } from 'src/shared/constants';
import { ContractDocument, ContractDocumentStatus } from '../../contract-management.d';

import { Alert } from 'src/components/common/alert';
import { ConfirmModal } from 'src/components/common/modals';
import { documentDeletedAlertId } from './ContractDocuments.alerts';
import { isDefined } from 'src/utils';
import { LinkTextCell } from 'src/components/common/table/renderers';
import { paths } from '../../ContractManagement.paths';
import { selectedContractDocumentState } from './ContractDocuments.state';
import { useContractDocuments } from './useContractDocuments';
import { useDocuments } from 'src/service/documents/useDocuments';
import { useParams } from 'react-router-dom';
import { setSharedDetails } from '../../../../store/actions';
import { useDispatch } from 'react-redux';

interface DocumentsTableProps {
  data?: ContractDocument[];
  itemsPerPage?: number;
  maxItems?: number;
}

interface paramType {
contractId:string
}

export const ContractDocumentsTable = ({
  data = [],
  itemsPerPage = 10,
  maxItems = 0,
}: DocumentsTableProps) => {
  // - Contract Documents
  const { deleteContractDocument } = useContractDocuments();
  const { downloadDocument } = useDocuments();
  const params = useParams() as paramType ;
  const dispatch = useDispatch();

  // - Selected Contract Document
  const [selectedContractDocument, setSelectedContractDocument] = useRecoilState(
    selectedContractDocumentState,
  );
  const resetSelectedContractDocument = useResetRecoilState(selectedContractDocumentState);

  /// Local state
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  /// Delete row
  const hideDeleteModal = () => {
    setDeleteModalIsOpen(false);
  };

  const handleDeleteRowClick = useCallback(
    ({ id }: Id) => {
      const selectedDoc = data.find(doc => doc.id === id);
      Assert(
        isDefined(selectedDoc),
        'Expected: `selectedDoc` to exist in allContractDocuments',
        'src/components/buyer/awardbid/add-vendor-docs/ContractDocumentsTable.tsx',
      );
      /* istanbul ignore else */
      if (selectedDoc) {
        setSelectedContractDocument(selectedDoc);
        setDeleteModalIsOpen(true);
      }
    },
    [data, setSelectedContractDocument],
  );

  /**
   * Make a service call to delete a row and refresh the list.
   * TODO: move this callback to the container component?
   */
  async function deleteRow() {
    /* istanbul ignore else */
    dispatch(setSharedDetails({ showLoader: true }));
    if (selectedContractDocument?.id) {
      // const deleteResponse = await tryCatchLog(async () => {
      await deleteContractDocument(
        selectedContractDocument.id,
        selectedContractDocument.contractId ?? params?.contractId,
      );
      resetSelectedContractDocument();
    }
    hideDeleteModal();
    scrollToTop();
    dispatch(setSharedDetails({ showLoader: false }));
  }

  /**
   * Render deafult cell text.
   * @param original: table row data
   * @returns
   */
  const renderDeleteLabelPrefix = useCallback((original: ContractDocument) => {
    return commonLabels.delete;
  }, []);

  /**
   * Render cell title based upon row data.
   * @param original: table row data
   * @returns
   */
  const renderDeleteLabelTitle = useCallback((original: ContractDocument) => {
    return `${commonLabels.delete} '${original.document?.title}'`;
  }, []);

  const columnConfig = useMemo((): Column<ContractDocument>[] => {
    const handleDeleteClick = handleDeleteRowClick;

    return [
      {
        Header: 'Contract',
        accessor: 'contractId',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: (cellData: any) => {
          const contractDoc = { ...cellData.row.original };
          return (
            <DSLink to={paths.navigation.contractDetails(contractDoc.contractId)}>
              {contractDoc.contract}
            </DSLink>
          );
        },
      },
      {
        Header: 'Title',
        accessor: 'title',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: (cellData: any) => {
          const contractDoc = { ...cellData.row.original };

          return contractDoc.title ? (
            <DSLink
              onClick={() => {
                if (contractDoc.document) downloadDocument(contractDoc.document);
              }}
            >
              {contractDoc.title}
            </DSLink>
          ) : (
            <em>Awaiting Upload</em>
          );
        },
      },
      {
        Header: 'Document Type',
        accessor: 'type',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: (cellData: any) => {
          const contractDoc = { ...cellData.row.original };
          //TODO this will need to be a download link
          // return contractDoc.type?.title;
          return contractDoc.type;
        },
      },
      { Header: 'Source', accessor: 'source' },
      {
        Header: 'Date',
        accessor: 'dtUpdated',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: (cellData: any) => {
          const contractDoc = { ...cellData.row.original };
          //TODO this will need to be a download link
          return displayDate(contractDoc.dtUpdated);
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: (cellData: any) => {
          const contractDoc = { ...cellData.row.original };

          // Red Pill for Overdue
          let pillType = DSPillType.Error;
          switch (contractDoc.status) {
            // case ContractDocumentStatus.Requested:
            //   pillType = DSPillType.Warning;
            //   break;
            case ContractDocumentStatus.Uploaded:
              pillType = DSPillType.Success;
              break;
          }
          return <DSPill type={pillType}>{contractDoc.status}</DSPill>;
        },
      },
      { Header: 'Public', accessor: 'public' },
      {
        Header: '',
        accessor: 'id',
        Cell: LinkTextCell({
          idField: 'id',
          labelField: '',
          onClick: handleDeleteClick,
          renderLabelPrefix: renderDeleteLabelPrefix,
          renderLabelTitle: renderDeleteLabelTitle,
        }),
        maxWidth: 30,
        disableSortBy: true,
      },
    ] as any;
  }, [downloadDocument, handleDeleteRowClick, renderDeleteLabelPrefix, renderDeleteLabelTitle]);

  const sortRule: SortingRule<keyof ContractDocument>[] = [
    { id: 'dtUpdated', desc: false },
    { id: 'contract', desc: false },
    { id: 'title', desc: false },
  ];

  const confirmMessageComponent = useMemo(() => {
    const docDisplayTitle = selectedContractDocument
      ? `${selectedContractDocument.title}`
      : 'this document';
    return (
      <>
        <SpanSpacerRight>{ControlLabels.confirmDeletePrefix}</SpanSpacerRight>
        <BoldText>{docDisplayTitle}</BoldText>
        <SpanSpacerLeft>{'?'}</SpanSpacerLeft>
      </>
    );
  }, [selectedContractDocument]);

  return (
    <>
      <Alert id={documentDeletedAlertId} allowDismiss />
      {maxItems ? (
        <DSTable
          columns={columnConfig}
          data={data.slice(0, maxItems)}
          dataTestId={'documents-table-unpaginated'}
          emptyMessage={commonLabels.notFound}
          sort={sortRule}
        />
      ) : (
        <DSPaginate data={data} pageSize={itemsPerPage} disableDeepLink>
          {data => (
            <DSTable
              columns={columnConfig}
              data={data}
              dataTestId={'documents-table'}
              emptyMessage={commonLabels.notFound}
              sort={sortRule}
            />
          )}
        </DSPaginate>
      )}

      {/* Delete Contract Document confirmation modal */}
      <ConfirmModal
        onConfirm={deleteRow}
        danger='delete'
        title={commonLabels.confirmDelete}
        closeModal={hideDeleteModal}
        isOpen={deleteModalIsOpen}
      >
        {confirmMessageComponent}
      </ConfirmModal>
    </>
  );
};
