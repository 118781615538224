import { call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes';
import * as bidApi from '../services/bids';
import { toastFn } from 'src/utils/helpers';

function* watchMemberInfoAsync() {
  yield takeLatest(actionTypes.LOAD_MEMBER_INFO.TRIGGER, memberInfoAsync);
}

function* memberInfoAsync(action: {
  payload: { memberId: number | string; isCurrentMember: boolean };
}): any {
  yield put({ type: actionTypes.LOAD_MEMBER_INFO.REQUEST, meta: action.payload });
  try {
    const { memberId = '', isCurrentMember = false } = action.payload;
    if (isCurrentMember === false && (!memberId || memberId === '')) {
      yield put({ type: actionTypes.LOAD_MEMBER_INFO.FAILURE });
    }
    const response = yield call(bidApi.getMemberInfo, action.payload);

    yield put({ type: actionTypes.LOAD_MEMBER_INFO.SUCCESS, payload: response.data.result });
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { internalLoader: false } });
  } catch (error) {
    yield put({
      type: actionTypes.LOAD_MEMBER_INFO.FAILURE,
      payload: { Error, payload: action.payload },
    });
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { internalLoader: false } });
  }
}

function* WatchGetUserPreference() {
  yield takeLatest(actionTypes.GET_USER_PREFERENCE.TRIGGER, getUserPreferenceAsync);
}

function* getUserPreferenceAsync(): any {
  yield put({ type: actionTypes.GET_USER_PREFERENCE.REQUEST, meta: {} });
  try {
    const response = yield call(bidApi.getUserPreference);
    const payload = { groupedBroadcast: response.data.data.broadcastType === 'UB' ? true : false,
      allowExteranlBidNotifications:response.data.data.bRexternalbid };
    yield put({
      type: actionTypes.SET_MEMBER_INFO_DETAILS.TRIGGER,
      payload,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_USER_PREFERENCE.FAILURE,
      payload: { Error, payload: {} },
    });
  }
}

function* WatchUpdateUserPreference() {
  yield takeLatest(actionTypes.UPDATE_USER_PREFERENCE.TRIGGER, updateUserPreferenceAsync);
}

function* updateUserPreferenceAsync(action: { payload: { groupedBroadcast: boolean ,bRexternalbid:boolean} }): any {
  yield put({ type: actionTypes.UPDATE_USER_PREFERENCE.REQUEST, meta: action.payload });
  try {
    const groupedBroadcast = action.payload.groupedBroadcast ? 'UB' : 'SB';
    yield call(bidApi.updateUserPreferences, { groupBroadcast: groupedBroadcast ,bRexternalbid:action.payload.bRexternalbid});

    toastFn('success', 'Saved', 'bdd');
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_USER_PREFERENCE.FAILURE,
      payload: { Error, payload: action.payload },
    });
  }
}

export function* memberInfo() {
  yield fork(watchMemberInfoAsync);
  yield fork(WatchGetUserPreference);
  yield fork(WatchUpdateUserPreference);
}
