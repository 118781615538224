import CryptoJS from 'crypto-js';

function encryptPassword(username:any, password:any) {
  try {
    const input = (username?.toUpperCase() || '') + password;
    const hash = CryptoJS.MD5(input).toString(CryptoJS.enc.Hex);
    return hash;
  } catch (error) {
    return error;
  }
}
export default encryptPassword;
