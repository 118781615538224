import dayjs, { Dayjs } from 'dayjs';
import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useEffect } from 'react';

import {
  DSCheckField,
  DSDateField,
  DSSelectField,
  DSTextAreaField,
  DSTextField,
} from '@demandstar/components/fields';
import { Flex, FlexContainer, H3, Large } from '@demandstar/components/styles';
import { DSButton } from '@demandstar/components/button';

import * as texts from './AddReminder.texts';
import { formatPayloadDates, getEmailArray, validateEmailArray } from './AddReminder.utils';
import { anyDateBefore, scrollToTop } from 'src/utils/helpers';
import { ContractReminder } from './hook/reminders';
import { useContractDetails } from '../../useContractDetails';
import { useContractSearch } from '../../search';
import { useIdFromRoute } from 'src/shared/hooks/useIdFromRoute';
import { useReminders } from './hook/useReminders';
import { setSharedDetails } from '../../../../store/actions';
import { useDispatch } from 'react-redux';

type AddReminderForm = Omit<ContractReminder, 'id' | 'dueDate' | 'remindDate' | 'emails'> & {
  dueDate: Dayjs | null;
  emails: '' | string[];
  remindDate: Dayjs | null;
};

interface AddReminderProps {
  onCreate?: (reminder?: ContractReminder) => void;
}

export function AddReminder({ onCreate }: AddReminderProps) {
  const { createReminder } = useReminders();
  const { contractId } = useIdFromRoute();
  const dispatch = useDispatch();

  const methods = useForm<AddReminderForm>({
    mode: 'onTouched',
    defaultValues: {
      remindDate: null,
      emails: '',
      sendCopy: false,
      subject: '',
      body: '',
      contractId: contractId || '',
      dueDate: null,
    },
  });

  const { setValue, handleSubmit, watch } = methods;
  const { allContracts } = useContractSearch();
  const { contractDetails } = useContractDetails(contractId);

  const remindDate = watch('remindDate');
  const dueDate = watch('dueDate') as any;

  const anyDateBeforeRemindDate = useCallback(
    (date: Dayjs) => {
      if (remindDate) {
        return anyDateBefore(date, remindDate);
      }
      return false;
    },
    [remindDate],
  );

  useEffect(() => {
    if (remindDate) {
      if (!dueDate || dueDate.isBefore(remindDate)) {
        setValue('dueDate', dayjs(remindDate) as never);
      }
    }
  }, [dueDate, remindDate, setValue]);

  async function submitReminder(data: AddReminderForm) {
    dispatch(setSharedDetails({ showLoader: true }));
    const reminder = await createReminder({
      ...data,
      dueDate:formatPayloadDates(data.dueDate),
      remindDate:formatPayloadDates(data.remindDate),
      emails: data.emails ? getEmailArray(data.emails as any) : [],
      id: '',
    });
    if (onCreate) onCreate(reminder);
    scrollToTop();
    dispatch(setSharedDetails({ showLoader: false }));

  }

  return (
    <>
      <H3 id='add-reminder-header' data-testid='add-reminder-header'>
        {texts.addReminderHeader}
      </H3>
      <Large marginBottom>{texts.addReminderDescription}</Large>
      <FormProvider {...methods}>
        <FlexContainer>
          <DSDateField
            name='remindDate'
            label={texts.form.labels.remindDate}
            fixedWidth
            inline
            disabledDate={anyDateBefore as any}
          />
          <DSDateField
            name='dueDate'
            label={texts.form.labels.dueDate}
            fixedWidth
            inline
            inactive={!remindDate}
            disabledDate={anyDateBeforeRemindDate}
          />
        </FlexContainer>
        <DSTextField
          name='emails'
          label={texts.form.labels.sendEmail}
          message={texts.form.messages.sendEmail}
          rules={{
            setValueAs: getEmailArray,
            validate: {
              checkEmails: validateEmailArray,
            },
          }}
        />
        <DSCheckField name='sendCopy' label={texts.form.labels.sendCopy} />
        <DSTextField
          name='subject'
          label={texts.form.labels.subject}
          message={texts.form.messages.subject}
        />
        <DSSelectField
          name='contractId'
          options={allContracts}
          labelField='name'
          valueField='id'
          label={texts.form.labels.contract}
          isDisabled={!!contractDetails}
        />
        <DSTextAreaField
          optional
          name='body'
          dataTestId='add-reminder-body'
          label={texts.form.labels.body}
        />
        <FlexContainer justifyContent='end'>
          <Flex grow={0}>
            <DSButton onClick={handleSubmit(submitReminder)}>{texts.form.submit}</DSButton>
          </Flex>
        </FlexContainer>
      </FormProvider>
    </>
  );
}
